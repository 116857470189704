<template>
    <div class="hello">
        <div class="mation">
            <div class="MationBox"   >
                <div class="MationImg"><img src="../../assets/adr.png"></div>
                <div>
                    <div class="MationAdress">地址：</div>
                    <div class="Mationadress">杭州市文二西路780号西溪银座D座5楼</div>
                </div>
            </div>
            <div class="MationBox"   @click="TeleEmail('电话')">
                <div class="MationImg"><img src="../../assets/tel.png"></div>
                <div>
                    <div class="MationAdress">电话：</div>
                    <div class="Mationadress">0571-86775951</div>
                </div>
            </div>
            <div class="MationBox"    @click="TeleEmail('邮箱')">
                <div class="MationImg"><img src="../../assets/email.png" style="margin-top: 23px;"></div>
                <div>
                    <div class="MationAdress">邮箱：</div>
                    <div class="Mationadress">marketing@easontime.com</div>
                </div>
            </div>
        </div>
        <div class="footerNav">
            <van-collapse v-model="activeNames" :border="false">
                <van-collapse-item v-for="(item,index) in menuData" :key="index">
                    <span slot="title" style=" display: flex; justify-content: space-between;" >
                        <div @click="item.name=='RFID蚀刻天线'?ProductDetail(item.name,10):headline(item.name,item.list)" class="footName">{{item.name}}</div>
                        <van-icon name="plus"></van-icon>
                    </span>
                    <div  class="footer_Nav" @click="item.name=='RFID蚀刻天线'?ProductDetails(item.name,item.list,1):ProductDetails(item.name,item.list,2)" v-if="item.name=='RFID蚀刻天线'?false:true">
                        {{item.Elheadname}}系统详情
                    </div>
                    <div v-for="(items,indexs) in item.list" :key="indexs" class="footer_Nav" @click="items.productList.length==1?productInside(items.productList,2,item.name,items):Product_Slevel(items,1,item.name)">
                        {{items.name}}
                    </div>
                </van-collapse-item>
                <van-collapse-item>
                    <span slot="title" style="display: flex;justify-content: space-between;">
                        <div  @click="ToSolution">解决方案</div>
                        <van-icon name="plus"></van-icon>
                    </span>
                    <div v-for="(item3,index3) in Solutionlist" :key="index3">
                        <div class="solution_Nav" @click="headSolution(item3.id,item3.Name)">
                            {{item3.name}}
                        </div>
                        <div v-for="(item4,index4) in item3.sublist" :key="index4" class="footer_Nav"
                            @click="headSolutionsub(item4,item3)">
                            {{item4.solutionTitle}}
                        </div>
                    </div>
                </van-collapse-item>
                <van-collapse-item><span slot="title" style="    display: flex;
                    justify-content: space-between;"> 快速链接
                        <van-icon name="plus"></van-icon>
                    </span>
                    <div v-for="(item1,index1) in otherlist" :key="index1">
                        <div v-for="(item2,index2) in item1.sublist" :key="index2" class="footer_Nav"
                            @click="Todownload(item2.name)">
                            {{item2.name}}
                        </div>
                    </div>
                </van-collapse-item>
            </van-collapse>
            <div class="moblieImg">
                <img src="@/assets/erweima.png" class="moblieimg">
                <div class="mobileText">关注公众号</div>
            </div>
        </div>
        <div class="footerTabbar">
            <van-tabbar>
                <van-tabbar-item icon="envelop-o" @click="headEmail">邮箱</van-tabbar-item>
                <van-tabbar-item icon="phone-o" @click="headTele">电话</van-tabbar-item>
                <van-tabbar-item icon="comment-o">联系</van-tabbar-item>
                <van-tabbar-item icon="back-top" @click="backTop" >置顶</van-tabbar-item>
            </van-tabbar>
        </div>
    </div>
</template>

<script>
    import { navlist, navSolution } from '@/api/navs/index'
    export default {
        name: 'FooterNav',
        props: {
            msg: String
        },
        data() {
            return {
                AdressList: [
                    {
                        img: '',
                        Adress: '地址',
                        adress: '杭州市文二西路780号西溪银座D座5楼'
                    },
                    {
                        img: '',
                        Adress: '电话',
                        adress: '0571-86775951'
                    },
                    {
                        img: '',
                        Adress: '邮箱',
                        adress: 'marketing@easontime.com'
                    }
                ],
                activeNames: ['1'],
                menuData: [],
                otherlist: [{
                    name: '快速链接',
                    sublist: [
                        { name: '新闻中心' }, { name: '公司介绍' }, { name: 'PDF下载' }
                    ]
                }
                ],
                Solutionlist: [],
                solutionId:'',
            }
        },
        mounted() {
            this.init()
            this.initSolution()
            setTimeout(() => {
                window.addEventListener("scroll", this.handleScroll);
            }, 100)
        },
        methods: {
            init() {
                navlist()
                    .then((res) => {
                        let newarr = []
                        res.data.data.map((item) => {
                            var elname = item.name.replace(/[^a-zA-Z]/g, "")
                            item.name = String(item.name).replace('-', '')
                            newarr.push(Object.assign(item, { Elheadname: elname }))
                        })
                        this.menuData = newarr;
                        // console.log(this.menuData,"me")
                    })
                    .finally(() => {
                        // Toast.clear()
                    })
            },

            initSolution() {
                navSolution()
                    .then((res) => {
                        for (var k in res.data.data) {
                            res.data.data[k].forEach(item=>{
                                // console.log(item.solutionClassId)
                                this.solutionId=item.solutionClassId
                            })
                            this.Solutionlist.push({ name: k, sublist: res.data.data[k], Name: k,id:this.solutionId })
                        }
                        this.Solutionlist.forEach((item) => {
                            item.name = item.name.replace(/[^\u4E00-\u9FA5]/g, '')
                        })
                        // console.log(this.Solutionlist,"this.Solutionlist")

                    })
                    .finally(() => {
                        // Toast.clear()
                    })
            },
            TeleEmail(name) {
                if (name == '电话') {
                    var phone='0571-86775951'
                    window.location.href = 'tel:' + phone
                } else if (name == '邮箱') {
                    window.location.href = "https://mail.163.com/"
                }
            },
            headEmail() {
                window.location.href = "https://mail.163.com/"
            },
            headTele() {
                window.location.href = 'tel:0571-86775951'
            },
            backTop() {
                window.scrollTo(0, 0)
            },
            headline(name, row) {
                row.forEach(item => {
                    this.$router.push({
                        name: 'Product_Level',
                        query: {
                            pid: item.pid,
                            name: name
                        }
                    })
                })
            },
            ProductDetail(name, i) {
                this.$router.push({
                    name: 'ProductDetail',
                    query: {
                        id: i,
                        name: name
                    }
                })
            },
            ProductDetails(name,row,i){
                // console.log(name,row,i)
                if (i == 1) {
                    this.$router.push({
                        name: 'ProductDetail',
                        query: {
                            id: 10,
                            name: name
                        }
                    })
                } else {
                    row.forEach(item => {
                        this.$router.push({
                            name: 'ProductDetail',
                            query: {
                                id: item.pid,
                                name: name
                            }
                        })
                    })
                }

            },
            ToSolution() {
                this.$router.push({ name: "Solution" });
            },
            Todownload(name) {
                if (name == "PDF下载") {
                    this.$router.push({ name: "Download" });
                } else if (name == "公司介绍") {
                    this.$router.push({ name: "AboutUs" });
                } else if (name == "新闻中心") {
                    this.$router.push({ name: "NewsCenter" });
                }

            },

            
            headSolution(pid,Name) {
                // console.log(pid, "pid",name)
                var name=Name.split('-').slice(0)[1]
                this.$router.push({ name: 'Solution', params: { pid: this.solutionClassId ,name:name} })
            },
            headSolutionsub(row, rows) {
                this.$router.push({ path: '/solutionDetail', query: { id: row.id, solutionClassName: rows.Name, name: row.solutionTitle } })
            },
            productInside(row, len, name,data) {
                // console.log("data", row, len, name)
                name=name.replace(/[^a-zA-Z]/g, "")
                // console.log(name)
                var path = "NaviGation"
                if (len == 2) {
                    row.forEach(item => {
                        this.$router.push({
                            name: 'Product_inside',
                            query: {
                                id: item.id,
                                pic: item.pic,
                                navName: name,
                                navNames: item.productClassName,
                                pictitle: item.productName,
                                picdescription: item.mainDescription,
                                longPic: item.mobileBannerPic,
                                path: path,
                                len: len,
                            productData: JSON.stringify(data)

                            }
                        })
                    })
                }
            },
            Product_Slevel(row, len, name) {
                // console.log("data", row, len, name)
                name=name.replace(/[^a-zA-Z]/g, "")
                var path = 'NaviGation'
                this.$router.push({
                    name: 'Product_Slevel',
                    query: {
                        name: row.name,
                        title: name,
                        description: row.description,
                        bannerDesc: row.bannerDescription,
                        bannerPic: row.mobileBannerPic,
                        productDesc: row.description2,
                        pid: row.pid,
                        path: path,
                    }
                })
            },
        },
    }
</script>

<style scoped>
    .hello {
        width: 100%;
        z-index: 999;
        font-family: Source Han Sans CN;
        margin-top: 100px;
    }

    .mation {
        padding: 0 30px 26px;
        box-sizing: border-box;
    }

    .MationBox {
        width: 690px;
        height: 182px;
        background: #FFFFFF;
        border: 1px solid #DCDCDC;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 0 24px;
        box-sizing: border-box;
        margin-bottom: 30px;
    }

    .MationImg {
        width: 138px;
        height: 138px;
        /* opacity: 0.1; */
        /* background-color: #6DA1FF; */
        margin-right: 14px;
    }

    .MationAdress,
    .Mationadress {
        font-size: 24px;
        font-weight: 400;
        color: #6DA1FF;
        line-height: 41px;
    }

    .Mationadress {
        color: #333333;
    }

    .footerNav {
        width: 100%;
        background: #434343;
        padding: 34px 30px 58px;
        box-sizing: border-box;
    }

    ::v-deep .footerNav .van-collapse-item {
        box-sizing: border-box;
        padding: 40px 0px;
    }

    ::v-deep .footerNav .van-cell {
        font-size: 32px;
        font-weight: 500;
        color: #FFFFFF;
        padding: 0px;
            line-height: 32px; 
            height: 40px;
    }

    ::v-deep .footerNav .van-icon {
        font-weight: bold;
    }

    ::v-deep .footerNav .van-cell,
    ::v-deep .footerNav .van-collapse-item__content {
        background: #434343;
        position: static
    }

    ::v-deep .footerNav .van-collapse-item__content {
        /* font-size: 24px; */
        font-weight: 400;
        color: #FFF;
        line-height: 60px;
        margin-bottom: -20px;
    }

    .solution_Nav {
        font-size: 24px;
    }

    .footer_Nav {
        font-size: 20px;

    }

    ::v-deep .footerNav .van-collapse-item,
    ::v-deep .footerNav .van-hairline--top-bottom {
        position: static
    }

    ::v-deep .footerNav .van-cell__right-icon {
        display: none;
    }


    .footerTabbar{
        position: fixed;
        bottom: 0;
    }
    .footerTabbar,
    ::v-deep .footerTabbar .van-tabbar {
        width: 100%;
        height: 97px;
        background: #FFFFFF;
        box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
    }

    ::v-deep .footerTabbar .van-tabbar--fixed {
        position: static;
    }

    ::v-deep .footerTabbar .van-tabbar-item__icon {
        font-size: 36px;
        color: #6DA1FF;
    }

    ::v-deep .footerTabbar .van-tabbar-item__text {
        font-size: 24px;
    }

    ::v-deep .footerTabbar .van-tabbar-item,
    ::v-deep .footerTabbar .van-tabbar-item--active {
        color: #999999;
    }

    ::v-deep .footerTabbar .van-hairline--top-bottom::after,
    ::v-deep .footerTabbar .van-hairline-unset--top-bottom::after {
        position: static;
    }

    .moblieImg {
        width: 141px;
        margin: 39px auto 0;
    }

    .moblieImg img {
        width: 141px;
        height: 141px;
    }

    .mobileText {
        font-size: 28px;
        font-weight: 500;
        color: #FFFFFF;
        margin-top: 23px;
    }
    .footName{
        height: 40px;
    }
</style>