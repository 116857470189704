import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

export const routesP = [
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: “about” */ '@/views/index.vue'),
  },
  {
    path: '/newsCenter',
    name: 'NewsCenter',
    component: () => import( /* webpackChunkName: “about” */ '@/views/NewsCenter.vue'),
  },
  {
    path: '/newsCenterDetail',
    name: 'NewsCenterDetail',
    component: () => import( /* webpackChunkName: “about” */ '@/views/NewsCenterDetail.vue'),
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Product.vue'),
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    component: () => import( /* webpackChunkName: “about” */ '@/views/ProductDetail.vue'),
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Solution.vue'),
  },
  {
    path: '/solutionDetail',
    name: 'SolutionDetail',
    component: () => import( /* webpackChunkName: “about” */ '@/views/SolutionDetail.vue'),
  },
  {
    path: '/Product_Level',
    name: 'Product_Level',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Product_Level.vue'),
  },
  {
    path: '/Product_Slevel',
    name: 'Product_Slevel',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Product_Slevel.vue'),
  },
  {
    path: '/Product_inside',
    name: 'Product_inside',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Product_inside.vue'),
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import( /* webpackChunkName: “about” */ '@/views/AboutUs.vue'),
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Contact.vue'),
  }, {
    path: '/Download',
    name: 'Download',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Download.vue'),
  }, {
    path: '/Search',
    name: 'Search',
    component: () => import( /* webpackChunkName: “about” */ '@/views/Search.vue'),
  }
]
export const routesM = [
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/index.vue'),
  },
  {
    path: '/newsCenter',
    name: 'NewsCenter',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/NewsCenter.vue'),
  },
  {
    path: '/newsCenterDetail',
    name: 'NewsCenterDetail',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/NewsCenterDetail.vue'),
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Product.vue'),
  },
  {
    path: '/productDetail',
    name: 'ProductDetail',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/ProductDetail.vue'),
  },
  {
    path: '/solution',
    name: 'Solution',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Solution.vue'),
  },
  {
    path: '/solutionDetail',
    name: 'SolutionDetail',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/SolutionDetail.vue'),
  },
  {
    path: '/Product_Level',
    name: 'Product_Level',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Product_Level.vue'),
  },
  {
    path: '/Product_Slevel',
    name: 'Product_Slevel',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Product_Slevel.vue'),
  },
  {
    path: '/Product_inside',
    name: 'Product_inside',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Product_inside.vue'),
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/AboutUs.vue'),
  },
  {
    path: '/Contact',
    name: 'Contact',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Contact.vue'),
  },
   {
    path: '/Download',
    name: 'Download',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Download.vue'),
  },
   {
    path: '/Search',
    name: 'Search',
    component: () => import( /* webpackChunkName: “about” */ '@/views/moblie/Search.vue'),
  }
]

var routes = [];
if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
  routes = routesM
} else {
  routes = routesP
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})


// 全局路由守卫
router.beforeEach((to, from, next) => {
  if (to.path !== '/shelfAdmin/shelfTemplateMall') next()
  window.scrollTo(0, 0)
})


export default router