<template>
    <div class="hello">
        <div class="footer">
            <div class="footer_top">
                <div><img src="../assets/logo.png" style=" filter: grayscale(100%) brightness(250%);" /></div>
                <div>
                    <h5 class="h5_top" @click="ToContact">联系我们</h5>
                    <div class="Fsubname">
                        <div><img src="@/assets/adress.png"></div>
                        <p class="FsubName">地址：杭州市文二西路780号D座5楼</p>
                    </div>
                    <div class="Fsubname">
                        <div><img src="@/assets/Fphone.png"></div>
                        <p class="FsubName">电话：<a :href="'tel:' +phone" class="a">{{phone}}</a></p>
                    </div>
                    <div class="Fsubname">
                        <div><img src="@/assets/xinxi.png"></div>
                        <p class="FsubName">邮箱：<a onclick="window.open('https://mail.163.com')"
                                class="a">marketing@easontime.com</a>
                        </p>
                    </div>
                </div>
                <div>
                    <h5 class="h5_top">关注我们</h5>
                    <div style="display: flex;justify-content: space-between;">
                        <div><a onclick="window.open('https://wx.qq.com')"><img src="../assets/weixin.png" /></a></div>
                        <div><a onclick="window.open('https://weibo.com')"><img src="../assets/weibo.png" /></a></div>
                    </div>
                </div>
                <div>
                    <h5 class="h5_top">关注公众号</h5>
                    <div> <a onclick="window.open('https://wx.qq.com')"><img src="../assets/erweima.png"
                                style="width: 59px;height: 59px;" /> </a></div>
                </div>
            </div>
            <div
                style="width:100%; height: 1px; background: #666666;margin: 0 auto;max-width: 1200px;margin-top: 39px;margin-bottom: 39px;">
            </div>
            <div class="footer_top_top">
                <div>
                    <div class="h5_top" @click="ToProduct">产品</div>
                    <div style="width: 480px;display: flex;flex-wrap: wrap;">
                        <div v-for="(item,index) in list" :key="index" class="foot_pro">
                            <h5 class="h5_subtitle"
                                @click="item.Chheadname=='蚀刻天线'?ProductDetail(item.Chheadname,item.list,1):headline(item,item.list)">
                                {{item.Chheadname}}</h5>
                            <div class="p_f" v-show="item.Chheadname=='蚀刻天线'?false:true">
                                <div class="subName"
                                    @click="item.Chheadname=='蚀刻天线'?ProductDetail(item.Chheadname,item.list,1):ProductDetail(item.Chheadname,item.list,2)">
                                    {{item.Elheadname}}系统详情</div>
                            </div>
                            <div class="p_f">
                                <div v-for="(items,indexs) in item.list" :key="indexs">
                                    <div class="subName"
                                        @click="items.productList.length==1?productInside(items.productList,2,item.Elheadname,items):Product_Slevel(items,1,item.Elheadname)">
                                        {{items.name}}</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <div class="h5_top" @click="ToSolution">解决方案</div>
                    <div style="width: 480px;display: flex;flex-wrap: wrap;">
                        <div v-for="(item,index) in Solutionlist" :key="index" class="foot_sol">
                            <h5 class="h5_subtitle" @click="headSolution(item.id,item.Name)">{{item.name}}</h5>
                            <div class="p_f">
                                <div v-for="(items,indexs) in item.sublist" :key="indexs">
                                    <div @click="headSolutionsub(items,item)" class="subName">
                                        {{items.solutionTitle}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div style="    margin-left: -16px;">
                    <div class="h5_top">快速链接</div>
                    <div v-for="(item,index) in otherlist" :key="index" style="margin-right:27px;">
                        <div class="p_f">
                            <div v-for="(items,indexs) in item.sublist" :key="indexs">
                                <div @click="Todownload(items.name)" class="subName">{{items.name}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_bottom">
            <div style="max-width: 1200px; margin: 0 auto;display: flex;align-items: center;justify-content: center;">
                <div style="opacity: 0.5;">
                    版权所有&nbsp;©2022杭州美思特科技股份有限公司版权所有
                </div>
                &nbsp;&nbsp;&nbsp;
                <!-- <div style="width:300px;margin:0 auto; padding:20px 0;"> -->
                <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602012915"
                    style="display:inline-block;text-decoration:none;height:20px;line-height:20px;
                    color: #FFFFFF;">
                    <img src="../assets/record.png" style="float:left;" />
                    <p
                        style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color: #FFFFFF;opacity: 0.5;">
                        浙公网安备 33010602012915号 浙ICP备16028457号-17</p>
                </a>
                <!-- </div> -->
            </div>
        </div>
    </div>
</template>

<script>
    import { navlist, navSolution } from '@/api/navs/index'
    export default {
        name: 'FooterNav',
        props: {
            msg: String
        },
        data() {
            return {
                list: [],
                Solutionlist: [],
                otherlist: [{
                    name: '快速链接',
                    sublist: [
                        { name: '新闻中心' }, { name: '公司介绍' }, { name: 'PDF下载' }
                    ]
                }
                ],
                systemname: '系统详情',
                systemid: '',
                solutionClassId: '',
                phone: "0571-86775951",
                InsideName: '',
                solutionId:'',
            }
        },
        mounted() {
            this.init()
            this.initSolution()
        },
        methods: {
            init() {
                navlist()
                    .then((res) => {
                        // // console.log(res, "footerres")
                        let newarr = []
                        this.list = res.data.data
                        res.data.data.map((item) => {
                            var elname = item.name.replace(/[^a-zA-Z]/g, "")
                            var chname = item.name.replace(/[^\u4E00-\u9FA5]/g, "")
                            newarr.push(Object.assign(item, { Elheadname: elname, Chheadname: chname }))
                        })
                        this.menuData = newarr;
                        // console.log(this.menuData ,"this.menuData ")
                    })
                    .finally(() => {
                        // Toast.clear()
                    })
            },
            
            initSolution() {
                navSolution()
                    .then((res) => {
                        for (var k in res.data.data) {
                            res.data.data[k].forEach(item=>{
                                // console.log(item.solutionClassId)
                                this.solutionId=item.solutionClassId
                            })
                            this.Solutionlist.push({ name: k, sublist: res.data.data[k], Name: k,id:this.solutionId })
                        }
                        this.Solutionlist.forEach((item) => {
                            item.name = item.name.replace(/[^\u4E00-\u9FA5]/g, '')
                        })
                    })
                    .finally(() => {
                        // Toast.clear()
                    })
            },
           
            ProductDetail(name, row, i) {
                // console.log("name", name, i)
                if (i == 1) {
                    this.$router.push({
                        name: 'ProductDetail',
                        query: {
                            id: 10,
                            name: name
                        }
                    })
                } else {
                    row.forEach(item => {
                        this.$router.push({
                            name: 'ProductDetail',
                            query: {
                                id: item.pid,
                                name: name
                            }
                        })
                    })
                }

            },
            headline(row, rows) {
                // console.log(row, "row", rows)
                rows.forEach(item => {
                    // console.log(item.pid, "item", row.name)
                    this.$router.push({
                        name: 'Product_Level',
                        query: {
                            pid: item.pid,
                            name: row.name
                        }
                    })
                })
            },
            Product_Slevel(row, len, name) {
                // console.log("data", row, len, name)
                var path = 'NaviGation'
                this.$router.push({
                    name: 'Product_Slevel',
                    query: {
                        name: row.name,
                        title: name,
                        description: row.description,
                        bannerDesc: row.bannerDescription,
                        bannerPic: row.mobilePic,
                        productDesc: row.description2,
                        pid: row.pid,
                        path: path,
                    }
                })
            },
            productInside(row, len, name, data) {
                console.log("data", row, len, name)
                var path = "NaviGation"
                if (len == 2) {
                    row.forEach(item => {
                        this.$router.push({
                            name: 'Product_inside',
                            query: {
                                id: item.id,
                                pic: item.pic,
                                navName: name,
                                navNames: item.productClassName,
                                pictitle: item.productName,
                                picdescription: item.mainDescription,
                                longPic: item.longPic,
                                path: path,
                                len: len,
                                productData: JSON.stringify(data)
                            }
                        })
                    })
                }
            },


            headSolution(pid, Name) {
                var name=Name.split('-').slice(0)[1]
                this.$router.push({ name: 'Solution', params: { pid: pid, name: name } })
            },
            headSolutionsub(row, rows) {
                // console.log(row, "row", rows)
                this.$router.push({ path: '/solutionDetail', query: { id: row.id, solutionClassName: rows.Name, name: row.solutionTitle } })
            },
            ToSolution() {
                this.$router.push({ name: "Solution" });
            },
            ToProduct() {
                this.$router.push({ name: "Product" });
            },
            ToContact() {
                this.$router.push({ name: "Contact" });
            },
            Todownload(name) {
                // console.log(name, "name")
                if (name == "PDF下载") {
                    this.$router.push({ name: "Download" });
                } else if (name == "公司介绍") {
                    this.$router.push({ name: "AboutUs" });
                } else if (name == "新闻中心") {
                    this.$router.push({ name: "NewsCenter" });
                }

            },
        },
    }
</script>

<style scoped>
    .hello {
        width: 100%;
        z-index: 999;
        font-family: Source Han Sans CN;
    }

    .footer {
        width: 100%;
        background: #434343;
        padding: 74px 360px 57px;
        box-sizing: border-box;
    }



    .footer_top,
    .footer_top_foot {
        display: flex;
        justify-content: space-between;
        box-sizing: border-box;
        padding-right: 153px;
    }

    .footer_top_top {
        display: flex;

    }

    .h5_top {
        font-weight: 500;
        color: #FFFFFF;
        margin-bottom: 18px;
    }

    .h5_top {
        font-size: 18px;
    }

    .h5_subtitle,
    .subName {
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 28px;
    }

    .subName {
        color: #B2B2B2;
    }

    .a {
        text-decoration: none;
        color: #999999;
    }


    .subName:hover,
    .a:hover,
    .FsubName:hover {
        color: #6DA1FF;
    }



    .footer_bottom {
        width: 100%;
        height: 60px;
        background: #313131;
        font-size: 15px;
        font-family: Avenir LT Std;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 60px;

    }

    .h5_top:hover,
    .subName:hover,
    .h5_subtitle:hover,
    .FsubName:hover {
        cursor: pointer;
    }

    /* @media (max-width: 950px) {
  .footer_top_top {
    display: none;
  }
} */
    /* @media screen and (max-width:950px) {
        .footer_top_top {
            display: block;
            background-color: pink;
        }
    } */
    .Fsubname {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
        display: flex;
        margin-bottom: 10px;
    }

    .Fsubname:last-child {
        margin-bottom: 0px;
    }

    .FsubName {
        margin-left: 10px;
    }


    .foot_pro:first-child {
        margin-right: 145px;
    }

    .foot_pro:nth-child(2n) {
        margin-right: 82px;
    }

    .foot_pro:nth-child(3) {
        margin-right: 72px;
        margin-top: -28px;
    }

    .foot_pro:nth-child(4) {
        margin-top: 28px;
    }

    .foot_pro:last-child {
        margin-top: 2px;
    }

    .foot_sol {
        margin-right: 163px;
    }

    .foot_sol:nth-child(3) {
        margin-top: -112px;
    }
</style>