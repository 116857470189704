// 接口统一地址
// export const HOST = '..'
// export const HOST = 'http://10.6.3.76:8093'
// export const HOST = 'http://10.6.3.23:8083'
// export const HOST = 'http://10.6.3.206:8093'
export const HOST = 'https://cms.ontimesmart.cn'
// export const HOST = 'http://8.136.113.15:8087'


export const MALL = '127.0.0.1:8090'


