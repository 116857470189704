<template>
    <div class="hello">
        <div class="Nav">
            <div @click="Toindex" class="logo"><img src="@/assets/logo.png"
                    style=" filter: grayscale(100%) brightness(250%);" /></div>
            <div>
                <i class="el-icon-search" @click="searchClick"></i>
                <i class="el-icon-s-fold" @click="popupShow=true"></i>
            </div>
            <van-popup v-model="popupShow" position="left">
                <div class="popLog">
                    <div @click="Toindex" class="logo"><img src="@/assets/logo.png" /></div>
                    <van-icon name="cross" class="vanicon" @click="popupShow=false" />
                    <!-- <div class="serach">
                        <i class="el-icon-search" style="color:#82AAFF;" @click="searchClick"></i>
                    </div> -->
                </div>
                <div>
                    <van-collapse v-model="activeNames">
                        <van-collapse-item v-for="(item, index) in navArr" :key="index"
                            :disabled="item.navList.length==0?true:false">
                            <span slot="title" :class="{activate: checkindex ==index }"
                                style="display: flex;justify-content: space-between;align-items: center;">
                                <div @click="pageChange(item,index)">{{item.name}}</div>
                                <van-icon name="play"
                                    v-if="item.name=='产品' || item.name=='关于我们' || item.name=='解决方案'?true:false"
                                    class="icon"></van-icon>
                            </span>
                            <div v-for="(items,indexs) in item.navList" :key="indexs"
                                @click="items.name=='蚀刻天线'?ProductDetail(items.name,1):headline(item.name,items.name,items.list,items.id)">
                                {{items.name}}
                            </div>
                        </van-collapse-item>
                    </van-collapse>
                </div>
                <div class="Email" @click="headEmail">
                    <van-icon name="envelop-o" class="Emailicon" />
                    发邮件
                </div>
            </van-popup>
            <van-popup position="top" v-model="searchShow" :style="{ height: '15%',width:'100%' }" closeable
                id="search">
                <van-search v-model="input" placeholder="搜索内容" @keydown="searchEnterFun" />
            </van-popup>

        </div>

    </div>
</template>

<script>
    import { navlist } from '@/api/navs/index'
    import { getSolutionClassList } from '@/api/solution/solution'
    export default {
        name: 'NaviGation',
        props: {
            msg: String,
            // checkindex:Number
        },
        data() {
            return {
                activeNames: ['1'],
                popupShow: false,
                searchShow: false,
                input: '',
                navArr: [
                    {
                        name: "首页",
                        id: "Home",
                        navList: [],
                    },
                    {
                        name: "产品",
                        id: "Product", navList: [],
                    },
                    {
                        name: "解决方案",
                        id: "Solution",
                        navList: [],
                    },
                    {
                        name: "新闻中心",
                        id: "NewsCenter", navList: [],
                    },
                    {
                        name: "关于我们",
                        id: "AboutUs",
                        navList: [{
                            name: '公司简介', id: "AboutUs"
                        }, {
                            name: '企业荣誉', id: "AboutUs"
                        }, {
                            name: '工厂展示', id: "AboutUs"
                        }],
                    },
                    {
                        name: "联系我们",
                        id: "Contact", navList: [],
                    },
                ],
                checkindex: 0,
            }
        },
        computed: {
            routeName() {
                // console.log(this.$route.name);
                return this.$route;
            },
        },
        watch: {
            $route(val) {
                console.log(val, "val")
                // document.title = val.name;
                switch (this.$route.name) {
                    case "Home":
                        this.checkindex = 0;
                        // document.title = '美思特智能 | ontime'
                        break;
                    case "Product":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Solution":
                        this.checkindex = 2;
                        // document.title = '解决方案 | ontime'
                        break;
                    case "NewsCenter":
                        this.checkindex = 3;
                        // document.title = '新闻中心 | ontime'
                        break;
                    case "AboutUs":
                        this.checkindex = 4;
                        document.title = '关于我们 | ontime'
                        break;
                    case "Product_Level":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Contact":
                        this.checkindex = 5;
                        document.title = '联系我们 | ontime'
                        break;
                    case "Download":
                        this.checkindex = 5;
                        document.title = '文件下载 | ontime'
                        break;
                    case "ProductDetail":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "NewsCenterDetail":
                        this.checkindex = 3;
                        // document.title = '新闻中心 | ontime'
                        break;
                    case "SolutionDetail":
                        this.checkindex = 2;
                        break;
                    case "Product_Slevel":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Product_inside":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Search":
                        this.checkindex = 5;
                        document.title = '搜索 | ontime'
                        break;
                }
            }
        },
        created() {
            // console.log(this.$route, "sa")
            switch (this.$route.name) {
                case "Home":
                    this.checkindex = 0;
                    // document.title = '美思特智能 | ontime'
                    break;
                case "Product":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Solution":
                    this.checkindex = 2;
                    // document.title = '解决方案 | ontime'
                    break;
                case "NewsCenter":
                    this.checkindex = 3;
                    // document.title = '新闻中心 | ontime'
                    break;
                case "AboutUs":
                    this.checkindex = 4;
                    document.title = '关于我们 | ontime'
                    break;
                case "Product_Level":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Contact":
                    this.checkindex = 5;
                    document.title = '联系我们 | ontime'
                    break;
                case "Download":
                    this.checkindex = 5;
                    document.title = '文件下载 | ontime'
                    break;
                case "ProductDetail":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "NewsCenterDetail":
                    this.checkindex = 3;
                    // document.title = '新闻中心 | ontime'
                    break;
                case "SolutionDetail":
                    this.checkindex = 2;
                    break;
                case "Product_Slevel":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Product_inside":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Search":
                    this.checkindex = 5;
                    document.title = '搜索 | ontime'
                    break;
            }
            // document.title = window.sessionStorage.getItem('title')
            // // console.log(this.checkindex, "标题", document.title)
            // if (this.checkindex == 0|| this.checkindex ==null|| document.title == null) {
            //     this.checkindex = 0
            //     document.title = "Home"
            // }
        },
        activated() {
        },
        mounted() {
            this.getSolutionFCatList()
            this.Moblieinit()
        },
        destroyed() {
            setTimeout(() => {
                window.removeEventListener("scroll", this.handleScroll);
                // clearInterval(this.play)
            }, 100)
        },
        methods: {
            headEmail() {
                window.location.href = "https://mail.163.com/"
            },
            Moblieinit() {
                navlist()
                    .then((res) => {
                        res.data.data.forEach(item1 => {
                            item1.name = String(item1.name).replace('-', '')
                            if (item1.name == "RFID蚀刻天线") {
                                item1.name = "蚀刻天线"
                            }
                        })
                        this.navArr.forEach(item => {
                            if (item.name == '产品') {
                                item.navList = res.data.data
                            }
                        })
                        // console.log(this.navArr, "this.navArr")

                    })
                    .finally(() => {
                        // Toast.clear()
                    })
            },
            async getSolutionFCatList() {
                // this.fClassList = []
                const data = await getSolutionClassList()
                if (data.status === 200) {
                    var fSeleClassList = data.data
                    fSeleClassList.forEach((item2) => {
                        if (item2.solutionClassName == 'BANNER') {
                            this.coverImgUrl = item2.mobileBannerPic
                            this.bannerText = item2.description
                        }
                        if (item2.solutionClassName.indexOf('BANNER') === -1) {
                            item2 = JSON.parse(JSON.stringify(item2).replace(/solutionClassName/g, "name"))
                            item2.name=item2.name.split('-').slice(0)[1] 
                            // console.log(item2,11)
                            // this.fClassList.push(item2)
                            this.navArr.forEach(item => {
                                if (item.name == '解决方案') {
                                    item.navList.push(item2)
                                }
                            })
                            // console.log(this.navArr, "this.navArr1")
                        }
                    })
                }

            },
            showPopup() {
                this.popupShow = true
            },
            searchClick() {
                if (this.searchShow == false) {
                    this.searchShow = true
                } else {
                    this.searchShow = false
                }
            },
            searchEnterFun(e) {
                var keyCode = window.event ? e.keyCode : e.which;
                // console.log("回车搜索", keyCode, e);
                if (keyCode == 13) {
                    this.clickSerach()
                }
            },
            clickSerach() {
                // console.log(this.input, typeof (this.input), "搜索")
                this.$router.push({ path: '/Search', query: { keywords: this.input } })
                this.searchShow = false
                this.input = ''
            },
            headline(Name, name, row, id) {
                console.log(name, "row", row, id)
                if (Name == '解决方案') {
                    // console.log("解决方案")
                    this.$router.push({  name: 'Solution', params: { pid: id, name: name } })
                } else if (Name == '关于我们') {
                    // console.log("关于我们")
                    this.$router.push({ name: id, params: { name: name } })
                    // this.$router.push({ name: id });
                } else {
                    row.forEach(item => {
                        // console.log(item, "item")
                        this.$router.push({
                            name: 'Product_Level',
                            query: {
                                pid: item.pid,
                                name: name
                            }
                        })
                    })
                }

                this.popupShow = false
            },
            Toindex() {
                this.$router.push({ name: "Home" });
            },
            pageChange(row, index) {
                // console.log(row, index)
                if (this.checkindex != index) {
                    this.checkindex = index;
                    this.$router.push({ name: row.id });
                }
                this.popupShow = false
            },
            ProductDetail(name, i) {
                // console.log(name, i)
                this.$router.push({
                    name: 'ProductDetail',
                    query: {
                        id: i,
                        name: name
                    }
                })
                this.popupShow = false
            },

        }

    }
</script>

<style scoped>
    .hello {
        width: 100%;
        z-index: 1999;
        font-family: Source Han Sans CN;
        position: fixed;
        top: 0px;
    }

    .Nav {
        width: 100%;
        height: 128px;
        background: #6DA1FF;
        padding: 55px 30px 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .el-icon-search,
    .el-icon-s-fold,
    .vanicon {
        color: #FFFFFF;
        font-size: 27px;
    }

    .vanicon {
        color: #82AAFF;
    }

    .el-icon-search {
        margin-right: 31px;
    }

    ::v-deep .Nav .van-popup {
        width: 560px;
        background: #FFF;
        height: 100%;
    }

    ::v-deep .Nav .van-cell__title {
        width: 100%;
        height: 80px;
        line-height: 80px;
    }

    ::v-deep .Nav .van-collapse-item__title--expanded .icon {
        transform: rotate(270deg);
    }

    .popLog {
        width: 560px;
        height: 129px;
        box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.3);
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        padding: 46px 30px 17px;
        box-sizing: border-box;
        align-items: center;
    }

    .Email {
        width: 312px;
        height: 80px;
        background: #82AAFF;
        border-radius: 8px;
        position: absolute;
        bottom: 88px;
        left: 124px;
        font-size: 28px;
        font-weight: 400;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .Emailicon {
        font-size: 48px;
        margin-right: 21px;
    }

    ::v-deep .Nav .van-cell {
        padding: 0 60px;
        height: 80px;
        align-items: center;
        font-size: 28px;
        font-weight: 400;
        color: #000000;
    }

    ::v-deep .Nav .van-cell__right-icon {
        color: #82AAFF;
        font-size: 24px;
        display: none;
    }

    .icon {
        color: #82AAFF;
        font-size: 24px;
        transform: rotate(90deg);
    }

    ::v-deep .Nav .van-hairline--top-bottom {
        z-index: 99;
    }

    ::v-deep .Nav .van-collapse-item__content {
        background: #F7F7F7;
        padding: 30px 0 30px 80px;
        font-size: 24px;
        font-weight: 400;
        color: #666666;
        align-items: center;
        line-height: 60px;
    }

    ::v-deep #search .van-search {
        width: 80%;
        margin: 60px auto 0;
    }

    ::v-deep #search .van-field__left-icon {
        display: none;
    }

    ::v-deep #search .van-icon-clear {
        display: block;
        font-size: 20px;
    }
</style>