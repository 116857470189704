<template>
  <div id="app">
    <!-- @contextmenu="handleMouse" -->
    <Nav v-if="moblie=='1'?true:false"></Nav>
    <NaviGation v-else></NaviGation>
    <keep-alive>
      <router-view :key="$route.fullPath"></router-view>
    </keep-alive>
    <Foot v-if="moblie=='1'?true:false"></Foot>
    <FooterNav v-else></FooterNav>
  </div>
</template>

<script>
  // 阻止鼠标放大
  document.addEventListener('mousewheel', function (e) {
    e = e || window.event;
    if ((e.wheelDelta && event.ctrlKey) || e.detail) {
      event.preventDefault();
    }
  }, {
    capture: false,
    passive: false
  });
  import Nav from '@/components/moblie/NaviGation.vue'
  import NaviGation from '@/components/NaviGation.vue'
  import Foot from '@/components/moblie/FooterNav.vue'
  import FooterNav from '@/components/FooterNav.vue'
  export default {
    name: 'App',
    components: {
      NaviGation,
      Nav,
      Foot,
      FooterNav,
    },
    data() {
      return {
        moblie: ""
      }
    },
    mounted() {
      // this.handleMouse()
      if (this.isMobile()) {
        this.moblie = '1'
      } else {
        // console.log("pc端");
        this.moblie = "2"
      }

    },
      created() {
      // this.$nextTick(() => {
      //   // 1.禁用右键菜单
      //   document.oncontextmenu = new Function("event.returnValue=false");
      //   // 2.禁用鼠标选中
      //   document.onselectstart = new Function("event.returnValue=false");
      //   // 3.禁止键盘F12键
      //   document.addEventListener("keydown", function (e) {
      //     if (e.key == "F12") {
      //       e.preventDefault(); // 如果按下键F12,阻止事件
      //     }
      //   });
      // });
    },

    methods: {
      isMobile() {
        let flag = navigator.userAgent.match(/(iphone|phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
        // localStorage.setItem('isiphone',flag)
        // console.log(flag)
        return flag;
      },
      handleMouse(e) {
        e.preventDefault()
      },
    }
  }
</script>


<style>
  #app {
    width: 100%;
    height: 100%;
  }

  * {
    padding: 0;
    margin: 0;
  }

  body {
    margin: 0 auto;
  }
</style>