<template>
    <div class="hello">
        <div class="Nav">
            <div class="navserach">
                <div class="text">
                    <p style="margin-right:54px ;">服务电话：0571-86775951</p>
                    <p><a href="https://mail.163.com/" class="p">E-mail：marketing@easontime.com</a></p>

                </div>
                <div class="serach" @click="serach"><i class="el-icon-search"></i>
                    <div>搜索</div>
                </div>
                <div class="serachinput" v-show="serachshow">
                    <el-input v-model="input" placeholder="搜索你想了解的信息" @keyup.enter.native="searchEnterFun">
                    </el-input>
                    <button class="button" @click="clickSerach">搜索</button>
                    <i class="el-icon-close" size="12" @click="handleclose">
                    </i>
                </div>
            </div>
        </div>
        <div class="Navs" :style="topNavBg">
            <div class="navText">
                <div @click="Toindex" class="logo"><img src="../assets/logo.png" /></div>
                <div style="display: flex;margin-right: -27px;">
                    <div class="nav_item" ref="navRef">
                        <!-- <a class="a" @click="pageChange(item)">{{item.name}} </a> -->
                        <div v-for="(item, index) in navArr" :key="index">
                            <div :class="{isDefault: true,activate: checkindex ==index }"
                                @click="pageChange(item,index)" @mouseover="pageover(item,index)"
                                @mouseleave="pageleave">
                                {{item.name}}
                            </div>
                        </div>
                        <div class="Showsclass">
                            <div class="showclass" v-show="show" @mouseleave="hideDropDown">
                                <el-tabs class="menu-tabs" v-model="tabActiveName" @tab-click="handleClick"
                                    ref="navTabs">
                                    <el-tab-pane v-for="(tab, i) in menuData" :key="i">
                                        <span slot="label">
                                            <div style="display: flex;width: 130px;margin: 29px 53px 0;"
                                                @click="tab.Chheadname=='蚀刻天线'?ProductDetail(tab.Chheadname,tab.list,1):headline(tab,tab.list)">
                                                <div id="tabs_titleimgs">
                                                    <i v-bind:class="tab.icon" id="tabs_titleimgs"
                                                        style="font-size:60px ;"></i>
                                                </div>
                                                <div class="tabs_titletext">
                                                    <div>{{tab.Elheadname}}</div>
                                                    <div>{{tab.Chheadname}}</div>
                                                </div>
                                            </div>
                                        </span>
                                        <div class="tabstext">
                                            <div class="tabstext_left">
                                                <div class="tabstext_title"
                                                    @click="tab.Chheadname=='蚀刻天线'?ProductDetail(tab.Chheadname,tab.list,1):headline(tab,tab.list)">
                                                    {{tab.Chheadname}}</div>
                                                <el-tabs :tab-position="tabPosition" v-model="tabActiveName2"
                                                    :id="'left-menu-'+i" ref="navTabs1">
                                                    <el-tab-pane v-for="(items,indexs) in tab.list" :key="indexs">
                                                        <span slot="label"
                                                            @click="items.productList.length==1?productInside(items.productList,2,tab.Elheadname,items):Product_Slevel(items,1,tab.Elheadname)">
                                                            <span class="tabstext_line"></span>
                                                            {{items.name}}
                                                        </span>
                                                        <div style="display: flex;flex-wrap: wrap;height: 40px;">
                                                            <div v-for="(subitem,subindex1) in items.productList"
                                                                :key="subindex1" class="tabsText_Con"
                                                                @click="productInside(subitem,1,tab.Elheadname,items)">
                                                                <p>{{subitem.productName}}</p>
                                                            </div>
                                                        </div>
                                                        <!-- <div style="width:740px;display: flex;flex-wrap: wrap;">
                                                            <div class="pro_parts_icon" v-for="(items,index) in item.productList" :key="index"
                                                                @click="item.productList.length==1?productInside(item.productList,2,item):productInside(items,1,item)">
                                                                <p >{{items.productName}}</p>
                                                                
                                                            </div>
                                                        </div> -->
                                                    </el-tab-pane>
                                                </el-tabs>
                                                <div class="tabstext_title" v-show="tab.Chheadname=='蚀刻天线'?false:true"
                                                    @click="tab.Chheadname=='蚀刻天线'?ProductDetail(tab.Chheadname,tab.list,1):ProductDetail(tab.Chheadname,tab.list,2)">
                                                    系统详情
                                                </div>
                                            </div>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                        </div>

                    </div>

                    <div style="display: flex;line-height: 80px;height: 80px;">
                        <p class="contact" @click="concat">联系我们</p>
                        <!-- <p @click="Todownload"><i class="el-icon-s-fold"></i></p> -->
                        <el-dropdown>
                            <!-- trigger="click" -->
                            <i class="el-icon-s-fold"></i>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item @click.native="Todownload">PDF下载</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </div>
        </div>



    </div>
</template>

<script>
    import { navlist } from '@/api/navs/index'

    export default {
        name: 'NaviGation',
        props: {
            msg: String,
            // checkindex:Number
        },
        data() {
            return {
                serachshow: false,
                topNavBg: {
                    backgroundColor: "",
                },
                input: '',
                checkindex: 0,
                navArr: [
                    {
                        name: "首页",
                        id: "Home",
                    },
                    {
                        name: "产品",
                        id: "Product",
                    },
                    {
                        name: "解决方案",
                        id: "Solution",
                    },
                    {
                        name: "新闻中心",
                        id: "NewsCenter",
                    },
                    {
                        name: "关于我们",
                        id: "AboutUs",
                    },
                    
                ],
                scrollTop: "",
                show: false,
                menuData: [],
                tabActiveName: '0',
                tabActiveName2: '0',

                navTop: 0,
                navLeft: 0,
                tabPosition: 'left',
                systemid: '',
                productId: '',
            }
        },
        computed: {
            routeName() {
                // console.log(this.$route.name);
                return this.$route;
            },
        },
        watch: {
            $route(val) {
                console.log(val, "val")
                // document.title = val.name;
                switch (this.$route.name) {
                    case "Home":
                        this.checkindex = 0;
                        // document.title = '美思特智能 | ontime'
                        break;
                    case "Product":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Solution":
                        this.checkindex = 2;
                        // document.title = '解决方案 | ontime'
                        break;
                    case "NewsCenter":
                        this.checkindex = 3;
                        // document.title = '新闻中心 | ontime'
                        break;
                    case "AboutUs":
                        this.checkindex = 4;
                        document.title = '关于我们 | ontime'
                        break;
                    case "Product_Level":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Contact":
                        this.checkindex = 5;
                        document.title = '联系我们 | ontime'
                        break;
                    case "Download":
                        this.checkindex = 5;
                        document.title = '文件下载 | ontime'
                        break;
                    case "ProductDetail":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "NewsCenterDetail":
                        this.checkindex = 3;
                        // document.title = '新闻中心 | ontime'
                        break;
                    case "SolutionDetail":
                        this.checkindex = 2;
                        break;
                    case "Product_Slevel":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Product_inside":
                        this.checkindex = 1;
                        // document.title = '产品 | ontime'
                        break;
                    case "Search":
                        this.checkindex = 5;
                        document.title = '搜索 | ontime'
                        break;
                }
            }
        },
        created() {
            // console.log(this.$route, "sa")
            switch (this.$route.name) {
                case "Home":
                    this.checkindex = 0;
                    // document.title = '美思特智能 | ontime'
                    break;
                case "Product":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Solution":
                    this.checkindex = 2;
                    // document.title = '解决方案 | ontime'
                    break;
                case "NewsCenter":
                    this.checkindex = 3;
                    // document.title = '新闻中心 | ontime'
                    break;
                case "AboutUs":
                    this.checkindex = 4;
                    document.title = '关于我们 | ontime'
                    break;
                case "Product_Level":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Contact":
                    this.checkindex = 5;
                    document.title = '联系我们 | ontime'
                    break;
                case "Download":
                    this.checkindex = 5;
                    document.title = '文件下载 | ontime'
                    break;
                case "ProductDetail":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "NewsCenterDetail":
                    this.checkindex = 3;
                    // document.title = '新闻中心 | ontime'
                    break;
                case "SolutionDetail":
                    this.checkindex = 2;
                    break;
                case "Product_Slevel":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Product_inside":
                    this.checkindex = 1;
                    // document.title = '产品 | ontime'
                    break;
                case "Search":
                    this.checkindex = 5;
                    document.title = '搜索 | ontime'
                    break;
            }
            // document.title = window.sessionStorage.getItem('title')
            // // console.log(this.checkindex, "标题", document.title)
            // if (this.checkindex == 0|| this.checkindex ==null|| document.title == null) {
            //     this.checkindex = 0
            //     document.title = "Home"
            // }
        },
        activated() {
        },
        mounted() {
            setTimeout(() => {
                window.addEventListener("scroll", this.handleScroll);
            }, 100)
            this.navTop =
                this.$refs.navRef.getBoundingClientRect().top +
                this.$refs.navRef.offsetHeight;
            this.navLeft =
                this.$refs.navRef.getBoundingClientRect().left +
                this.$refs.navRef.offsetWidth;
            // // console.log(this.navTop, this.navLeft, "this.navTop", typeof (this.navTop))
            this.init()
        },
        destroyed() {
            setTimeout(() => {
                window.removeEventListener("scroll", this.handleScroll);
                // clearInterval(this.play)
            }, 100)
        },
        methods: {
            pageChange(row, index) {
                // console.log(row, index)
                if (this.checkindex != index) {
                    this.checkindex = index;
                    this.$router.push({ name: row.id });
                }
            },
            searchEnterFun(e) {
                var keyCode = window.event ? e.keyCode : e.which;
                // console.log("回车搜索", keyCode, e);
                if (keyCode == 13) {
                    this.clickSerach()
                }
            },

            clickSerach() {
                // console.log(this.input, typeof (this.input), "搜索")
                this.$router.push({ path: '/Search', query: { keywords: this.input } })
                this.serachshow = false
                this.input = ''
            },
            serach() {
                // console.log("打开搜索", this.serachshow)
                if (this.serachshow == false) {
                    this.serachshow = true
                } else {
                    this.serachshow = false
                }
            },
            handleclose() {
                this.serachshow = false
                this.input = ''
            },
            handleScroll() {
                this.scrollTop =
                    window.pageYOffset ||
                    document.documentElement.scrollTop ||
                    document.body.scrollTop;
                if (this.scrollTop) {
                    if (this.scrollTop > 60) {
                        this.topNavBg.backgroundColor = 'rgba(255,255,255,0.8)'
                    } else if (this.scrollTop <= 60) {
                        this.topNavBg.backgroundColor = "rgba(255,255,255,0.7)";
                    }
                }
            },


            pageover(row, index) {
                console.log(index)
                if (row.name !== "产品") {
                    this.show = false
                } else {
                    this.show = true
                }
            },
            pageleave(e) {
                // console.log(e, "e")
                if (this.show && e.pageY <= this.navTop) this.show = false
            },
            hideDropDown(e) {
                // console.log(e, "e")
                if (this.show && e.pageY >= this.navTop) this.show = false
            },

            handleClick(tab, event) {
                console.log(tab, event);
            },
            concat() {
                this.$router.push({ name: 'Contact' });
            },
            Toindex() {
                this.$router.push({ name: "Home" });
            },
            Todownload() {
                this.$router.push({ name: "Download" });
            },

            init() {
                navlist()
                    .then((res) => {
                        // // console.log(res, "navres")
                        let newarr = []
                        this.menuData = res.data.data
                        res.data.data.map((item) => {
                            var elname = item.name.replace(/[^a-zA-Z]/g, "")
                            var chname = item.name.replace(/[^\u4E00-\u9FA5]/g, "")
                            newarr.push(Object.assign(item, { Elheadname: elname, Chheadname: chname }))
                        })
                        this.menuData = newarr;
                        // console.log(this.menuData, "this.menuData")
                        //自动点击
                        this.$nextTick(() => {
                            this.$refs.navTabs.$refs.nav.$nextTick(() => {
                                // 此时tab的nav才渲染dom 否则拿不到el-tabs__item
                                let target = document.getElementsByClassName("el-tabs__item is-top");
                                let that = this;
                                for (let i = 0; i < target.length; i++) {
                                    target[i].addEventListener("mouseover", () => {
                                        //切换左侧默认选项为第一个
                                        that.tabActiveName2 = "0";
                                        that.tabActiveName = target[i].getAttribute('id').split('-')[1]
                                        that.$refs.navTabs.handleTabClick(1, String(i));
                                        // // console.log(that.$refs.navTabs1[i].$refs.nav)
                                        that.$refs.navTabs1[i].$refs.nav.$nextTick(() => {
                                            // 此时tab的nav才渲染dom 否则拿不到el-tabs__item
                                            let target1 = document.getElementById('left-menu-' + i).getElementsByClassName("el-tabs__item is-left");
                                            let that = this;
                                            for (let j = 0; j < target1.length; j++) {
                                                target1[j].addEventListener("mouseover", () => {
                                                    that.tabActiveName2 = target[j].getAttribute('id').split('-')[1]
                                                    that.$refs.navTabs1[i].handleTabClick(1, String(j));
                                                });
                                            }
                                        });
                                    });
                                }
                            })
                        });

                    })
                    .finally(() => {
                        // Toast.clear()
                    })
            },


            ProductDetail(name, row, i) {
                // console.log("name", name, i)
                if (i == 1) {
                    this.$router.push({
                        name: 'ProductDetail',
                        query: {
                            id: 10,
                            name: name
                        }
                    })
                } else {
                    row.forEach(item => {
                        this.$router.push({
                            name: 'ProductDetail',
                            query: {
                                id: item.pid,
                                name: name
                            }
                        })
                    })
                }

            },
            headline(row, rows) {
                // console.log(row, "row", rows)
                rows.forEach(item => {
                    // console.log(item, "item")
                    this.$router.push({
                        name: 'Product_Level',
                        query: {
                            pid: item.pid,
                            name: row.name
                        }
                    })
                })
            },

            Product_Slevel(row, len, name) {
                // console.log("data", row, len, name)
                var path = 'NaviGation'
                this.$router.push({
                    name: 'Product_Slevel',
                    query: {
                        name: row.name,
                        title: name,
                        description: row.description,
                        bannerDesc: row.bannerDescription,
                        bannerPic: row.mobilePic,
                        productDesc: row.description2,
                        pid: row.pid,
                        path: path,
                    }
                })
            },
            productInside(row, len, name, data) {
                // console.log("data", row, len, name)
                var path = "NaviGation"
                if (len == 2) {
                    row.forEach(item => {
                        this.$router.push({
                            name: 'Product_inside',
                            query: {
                                id: item.id,
                                pic: item.pic,
                                navName: name,
                                navNames: item.productClassName,
                                pictitle: item.productName,
                                picdescription: item.mainDescription,
                                longPic: item.longPic,
                                path: path,
                                len: len,
                            productData: JSON.stringify(data)
                            }
                        })
                    })
                } else {
                    // console.log("1", row, len, name, data)
                    this.$router.push({
                        name: 'Product_inside',
                        query: {
                            id: row.id,
                            pic: row.pic,
                            navName: name,
                            navNames: row.productClassName,
                            pictitle: row.productName,
                            picdescription: row.mainDescription,
                            longPic: row.longPic,
                            path: path,
                            len: len,
                            productData: JSON.stringify(data)
                        }
                    })
                }

            },
        }

    }
</script>

<style scoped>
    .hello {
        width: 100%;
        z-index: 1999;
        font-family: Source Han Sans CN;
        position: fixed;

    }

    .Nav {
        width: 100%;
        height: 40px;
        background: #6DA1FF;
        line-height: 40px;
    }

    .navserach {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
    }

    .text {
        display: flex;
    }

    .p {
        text-decoration: none;
        color: #FFFFFF;
    }

    .p:hover {
        color: #FFFFFF;
    }

    .serach {
        display: flex;
    }

    .serachinput {
        position: absolute;
        top: 140px;
        z-index: 999;
        max-width: 1200px;
        margin: auto;
        padding: 0 0 0 300px;
        box-sizing: border-box;
    }

    .el-icon-close {
        position: absolute;
        right: 30px;
        top: 24px;
        z-index: 1000;
        color: #000000;
    }

    .button {
        position: absolute;
        right: 62px;
        top: 12px;
        z-index: 1000;
        width: 80px;
        height: 36px;
        background: #82AAFF;
        border-radius: 8px;
        border: none;
        font-size: 16px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 36px;
    }

    ::v-deep .serachinput .el-input__inner {
        width: 600px;
        height: 60px;
        background: #FFFFFF;
        box-shadow: 0px 5px 20px 4px rgba(109, 161, 255, 0.05);
        border-radius: 8px;
        padding-left: 30px;
        box-sizing: border-box;
    }

    .el-icon-search {
        position: relative;
        line-height: 40px;
        margin-right: 10px;
        color: #FFFFFF;
    }

    /* ::v-deep input::-webkit-input-placeholder {
        -webkit-text-fill-color: #FFFFFF;
    }

    ::v-deep input::-moz-input-placeholder {
        -webkit-text-fill-color: #FFFFFF;
    }

    ::v-deep input::-ms-input-placeholder {
        -webkit-text-fill-color: #FFFFFF;
    } */
    .Navs {
        width: 100%;
        height: 80px;
        background-color: rgba(255, 255, 255, 0.7);

    }

    .navText {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-right: 22px;
        box-sizing: border-box;
        /* position: absolute; */
        align-items: center;

    }

    .nav_item {
        display: flex;
        /* width: 484px; */
        width: 500px;
        height: 80px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        line-height: 80px;
        display: flex;
        justify-content: space-between;

    }

    .a:hover {
        color: #6DA1FF;
        font-weight: 800;
        cursor: pointer;
    }

    .Showsclass {
        width: 100%;
        position: absolute;
        top: 120px;
        left: 0px;
        background: #FFFFFF;
    }

    .showclass {
        max-width: 1200px;
        margin: 0 auto;
    }

    ::v-deep .menu-tabs .el-tabs__active-bar {
        background-color: transparent !important;
    }

    ::v-deep .menu-tabs .el-tabs__nav-wrap::after {
        position: static !important;
    }

    ::v-deep .menu-tabs .el-tabs__header,
    ::v-deep .menu-tabs .el-tabs__nav-scroll,
    ::v-deep .menu-tabs .el-tabs__nav {
        /* width: 100%; */
        height: 115px;
    }

    ::v-deep .menu-tabs .el-tabs__nav.is-top {
        width: 100%;
    }

    ::v-deep .menu-tabs .el-tabs__header {
        margin: 0px;
    }

    ::v-deep .menu-tabs .el-tabs__content {
        /* height: 320px; */
    }

    ::v-deep .menu-tabs .el-tabs__item {
        padding: 0;
        height: 100%;
    }

    ::v-deep .menu-tabs .el-tabs__item.is-active #tabs_titleimgs {
        /* filter: grayscale(0); */
        color: #82AAFF;

    }

    ::v-deep .menu-tabs .el-tabs__item.is-active .tabs_titletext {
        font-weight: bold;
        color: #82AAFF;
    }

    #tabs_titleimgs {
        width: 60px;
        height: 60px;
        margin-right: 10px;
        /* filter: grayscale(100%); */
        color: #999999;
        margin-top: 15px;

    }

    .tabs_titletext {
        width: 63px;
        font-size: 16px;
        font-weight: 500;
        color: #999999;
        line-height: 24px;
        margin-top: 9px;
    }

    .tabstext_left {
        width: 294px;
        background: #F7F7F7;
        /* height: 320px; */
        padding: 29px 0 120px 59px;
        box-sizing: border-box;
    }

    .tabstext_title {
        /* width: 52px; */
        height: 24px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        line-height: 24px;
    }

    ::v-deep .tabstext .el-tabs--left {
        margin: 20px 0;

    }

    ::v-deep .tabstext .el-tabs__header.is-left,
    ::v-deep .tabstext .el-tabs__nav-scroll,
    ::v-deep .tabstext .el-tabs__nav.is-left {
        /* width: 294px; */
        height: 100%;
        background: #F7F7F7;
    }

    ::v-deep .tabstext .el-tabs__content {
        position: absolute;
        top: 0px;
        left: 294px;
    }

    ::v-deep .tabstext .el-tabs__item.is-left {
        width: 240px;
        height: 40px;
        /* background: #FFFFFF; */
        text-align: left;
        line-height: 40px;
        padding-left: 30px;
        box-sizing: border-box;
    }

    ::v-deep .tabstext .el-tabs__item.is-left.is-active {
        background: #FFFFFF;
    }

    ::v-deep .tabstext .el-tabs__item.is-left:last-child,
    ::v-deep .tabstext .el-tabs__item.is-left:nth-child(2) {
        padding-left: 30px !important;
    }

    ::v-deep .tabstext .el-tabs__item.is-left.is-active .tabstext_line {
        width: 10px;
        height: 40px;
        background: #82AAFF;
        display: inline-block;
        position: absolute;
        top: 0px;
        left: 0px;
    }

    ::v-deep .tabstext .el-tabs__content {
        width: 906px;
        height: 100%;
        background-color: #FFFFFF;
        padding: 78px 0 0 119px;
        box-sizing: border-box;
    }

    .tabstext_content {}

    .tabsText_Con {
        width: 180px;
        height: 40px;
        margin-right: 50px;
        z-index: 9;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #DCDCDC;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: #666666;
        line-height: normal;
    }

    .tabsText_Con:hover {
        color: #82AAFF;
        cursor: pointer;
    }

    /* ::v-deep .tabstext .el-tabs--left .el-tabs__item.is-left {
        text-align: left;
    }

    ::v-deep .tabstext .el-tabs__nav.is-left {
        padding: 29px 0 0 54px;
        box-sizing: border-box;
    } */

    /* ::v-deep .tabstext .el-tabs__header,
    ::v-deep .tabstext .el-tabs__nav-scroll,
    ::v-deep .tabstext .el-tabs__nav,
    ::v-deep .tabstext  .el-tabs__nav-wrap.is-left {
        width: 294px;
        height: 320px;
        background: #F7F7F7;
        text-align: left;
    } */

    .activate {
        color: #6DA1FF;
        font-weight: 800;
    }

    .isDefault {
        padding: 0 24px;
        box-sizing: border-box;
        /* background-color: plum; */
    }

    .isDefault:hover,
    .tabstext_title:hover,
    .tabstext_content:hover {
        cursor: pointer;
    }

    .contact {
        width: 120px;
        height: 40px;
        border: 1px solid #6DA1FF;
        border-radius: 8px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #6DA1FF;
        line-height: 40px;
        text-align: center;
        margin: 18px 20px 0 60px;
    }

    .contact:hover {
        cursor: pointer;
        color: #FFFFFF;
        background: #6DA1FF;
    }

    .el-icon-s-fold {
        color: #6DA1FF;
        font-size: 20px;
        line-height: 40px;
    }

    .logo:hover,
    .serach:hover {
        cursor: pointer;
    }
</style>