import axios from 'axios'
import { HOST } from '@/api/baseApi'

// 解决方案分类
export function getSolutionClassList() {
  return axios({
    url: HOST + '/api/solutionClass/getList',
    method: 'get',
  })
}

// 解决方案父id
export function getSolutionFCatList() {
  return axios({
    url: HOST + '/api/solutionClass/getPid',
    method: 'get',
  })
}

// 一键修改
export function oneClickModification(data) {
  return axios({
    url: HOST + '/batchUpdate/oneClickModification',
    method: 'post',
    data
  })
}

// 一键绑定
export function oneClickBind() {
  return axios({
    url: HOST + '/batchUpdate/oneClickBind',
    method: 'post',
  })
}

// 获取详情信息
export function getSolutionById(data) {
  return axios({
    url: HOST + '/api/solution/getSolution',
    method: 'get',
    params: data
  })
}

export function getSolutionList(data){
  return axios({
    url: HOST + '/api/solution/getList?page=' + data.page + '&rows=' + data.rows,
    method: 'get',
    params: data
  })
}
