import Vue from 'vue'
import Vuex from "vuex"
Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        // 默认网站关键词
        metaInfo: { 
            title: "",
            keywords: "",
            description: ""
        }
    },
    mutations: {
        CHANGE_META_INFO(state, metaInfo) {
            // // console.log(metaInfo,"metaInfo")
            state.metaInfo = metaInfo;
        }
    },
    actions: {
    },
    modules: {
    }
})

