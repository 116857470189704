// // designWidth:设计稿的实际宽度值，需要根据实际设置
// // maxWidth:制作稿的最大宽度值，需要根据实际设置
// // 这段js的最后面有两个参数记得要设置，一个为设计稿实际宽度，一个为制作稿最大宽度，例如设计稿为750，最大宽度为750，则为(750,750)
// (function (designWidth, maxWidth) {
//     var doc = document
//     var win = window
//     var docEl = doc.documentElement
//     var remStyle = document.createElement('style')
//     var tid
   
//     function refreshRem () {
//       var width = docEl.getBoundingClientRect().width
//       maxWidth = maxWidth || 540
//       width > maxWidth && (width = maxWidth)
//       // 屏幕宽度 * 100 / 设计稿宽度
//       var rem = width * 100 / designWidth
//       remStyle.innerHTML = 'html{font-size:' + rem + 'px;}'
//     }
   
//     if (docEl.firstElementChild) {
//       docEl.firstElementChild.appendChild(remStyle)
//     } else {
//       var wrap = doc.createElement('div')
//       wrap.appendChild(remStyle)
//       doc.write(wrap.innerHTML)
//       wrap = null
//     }
//     // 要等 wiewport 设置好后才能执行 refreshRem，不然 refreshRem 会执行2次；
//     refreshRem()
   
//     win.addEventListener('resize', function () {
//       clearTimeout(tid) // 防止执行两次
//       tid = setTimeout(refreshRem, 300)
//     }, false)
   
//     win.addEventListener('pageshow', function (e) {
//       if (e.persisted) { // 浏览器后退的时候重新计算
//         clearTimeout(tid)
//         tid = setTimeout(refreshRem, 300)
//       }
//     }, false)
//     if (doc.readyState === 'complete') {
//       // doc.body.style.fontSize = '16px'
//     } else {
//       doc.addEventListener('DOMContentLoaded', function () {
//         // doc.body.style.fontSize = '16px'
//       }, false)
//     }
//   })(750, 750)
(function(){
    /* targetW的值为设计图的宽度大小,此时设置的宽度大小为640 */
   var targetW = 750;
   /* 获取视口缩放的比例 */
   var scale = document.documentElement.clientWidth/targetW;
   /* 获取到meta标签 */
   var meta =	document.querySelector("meta[name='viewport']");
   /* 向其添加缩放的比例 */
   meta.content="initial-scale="+scale+",minimum-scale="+scale+",maximum-scale="+scale+",user-scalable=no";
})()

