import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import './assets/fonts/iconfont.css';
import store from './store/store'
import Vant from 'vant';
import 'vant/lib/index.css';
// 使用vue-meta
import Meta from 'vue-meta';
Vue.use(Meta)
import '@/store/rem.js' // 引入移动端rem适配
import VueDirectiveImagePreviewer from 'vue-directive-image-previewer'
import 'vue-directive-image-previewer/dist/assets/style.css'
Vue.use(VueDirectiveImagePreviewer) 

//main.js
// import "swiper/swiper-bundle.css";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import "swiper/css/swiper.css";

Vue.use(VueAwesomeSwiper)
Vue.use(Vant)
Vue.use(ElementUI);
Vue.config.productionTip = false

Vue.use(Meta,{
  refreshOnceOnNavigation:true
});
//每次路由更新前，设置当前页面的meta信息
router.beforeEach((to, from, next) => {
if (to.meta.metaInfo) {
  store.commit("CHANGE_META_INFO", to.meta.metaInfo);
}
next();
});


new Vue({
  render: h => h(App),
  router: router,
  store,
  metaInfo() {
    return {
      title: this.$store.state.metaInfo.title,
      meta: [
        {
          name: "keywords",
          content: this.$store.state.metaInfo.keywords
        },
        {
          name: "description",
          content: this.$store.state.metaInfo.description
        }
      ]
    };
  },
}).$mount('#app')
