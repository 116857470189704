import axios from 'axios'
import { HOST } from '@/api/baseApi'

export function navlist(data) {
  return axios({
      url: HOST + '/api/app/AppBarInfo',
      method: 'get',
      data
  })
}
export function navSolution(data) {
    return axios({
        url: HOST + '/api/app/AppBarSolutionInfo',
        method: 'get',
        data
    })
  }

   
  export function navSearch(data) {
    return axios({
        url: HOST + '/search?keywords=' + data.keywords + '&pageNo=' + data.pageNo+ '&pageSize=' + data.pageSize,
        // url: HOST + '/search',
        method: 'get',
        data
    })
  }
  export function Pid(data) {
    return axios({
        url: HOST + '/api/productClass/getPid',
        // url: HOST + '/search',
        method: 'get',
        data
    })
  } 
  export function flag(data) {
    return axios({
        url:HOST +'/api/hostWord/getTDK?flag=' + data.flag,
        method: 'get',
        data
    })
  } 

  